import { Injectable } from '@angular/core';
import { Userpilot } from 'userpilot';

@Injectable({
  providedIn: 'root',
})
export class UserpilotService {
  public get userpilot(): typeof Userpilot {
    return Userpilot;
  }

  public isActive(): boolean {
    return !!(window as any)?.userpilot?.isRunning()?.active;
  }
}
